import * as React from "react"

import { Component } from "react";
import { StaticQuery, graphql } from "gatsby"
import PageManager from "../components/PageManager"
import Seo from "../components/Seo"
import PhilosophyPage from "../components/pages/PhilosophyPage"

export default class PhilosophyIndex extends Component 
{ 
    render()
    {
        const page = "philosophy";
    
        return <StaticQuery 
            query={graphql`query MetaPhilosophyQuery {
                site {
                    siteMetadata {
                        title
                        siteUrl
                        meta {
                            page: philosophy {
                                title
                                description
                            }
                        }
                    }
                }
            }`}
            render={data => {
                
                const { title, description } = data.site.siteMetadata.meta.page;
                const { location } = this.props;
                
                return (<PageManager page={page} data={data} windowLocation={location}>
                    <Seo page={page} title={title} description={description} />
                    <PhilosophyPage />
                    
                </PageManager>)
            }}/>
    }
}