/**
 *
 * Philosphy Page
 * 
*/

import React, { Fragment } from "react"

import BasePage from "./BasePage"
import Module from "../module/Module";
import ModuleBackground from "../module/ModuleBackground";
import ModuleContent from "../module/ModuleContent";
import ModuleLayer from "../module/ModuleLayer";
import TextLayer from "../module/layers/TextLayer";
import ImageLayer from "../module/layers/ImageLayer";
import VideoLayer from "../module/layers/VideoLayer";
import RichLayoutRow from "../richlayout/RichLayoutRow";
import RichLayoutColumn from "../richlayout/RichLayoutColumn";
import RichLayoutBody from "../richlayout/RichLayoutBody";
import Footer from "../Footer";
import ScrollDown from "../scrollDown/ScrollDown";
import { 
    philoData, 
} from "../../data/PageAssets";

export default class PhilosophyPage extends BasePage
{
    constructor(props)
    {
        super(props);
        
        this._isLazy = true;                        // lazy loading 
        this._assetPath = "philosophy";             // asset folder for page
        this._assets = philoData();                 // asset data

        this.state = {
            hasLoaded: false,
            hasLazyLoaded: false,
            hasResized: false,
        }
    }

    componentDidMount()
    {
        super.componentDidMount();
        
        // start initial asset loading...
        this.startAssets({ 
            isLazy: false, 
            onAssetEnd: () => this.onAssetEnd() 
        });
    }

    onAssetEnd()
    {
        this.setState({ hasLoaded: true });
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        const { hasLoaded, hasLazyLoaded } = this.state;

        if (hasLoaded !== prevState.hasLoaded)
        {
            this.initPage();
            return;
        }

        if (hasLazyLoaded !== prevState.hasLazyLoaded)
        {
            this.afterPageLoad();
        }
    }

    render()
    {
        const { hasLoaded, hasLazyLoaded: lz, hasResized: rz } = this.state;

        const getVid = this.pVideoNam("philo_loop");
        const mProps = {
            hasLazyLoaded: lz,
            hasResized: rz,
            device: this._devSize
        }

        const videoConfig = { 
            offset: 10, 
            position: "bottom center", 
            phone: { 
                offset: 30, 
                position: "bottom center" 
            } 
        }

        const videoConfig2 = { 
            offset: 10, 
            position: "bottom center", 
            phone: { 
                offset: 20, 
                position: "bottom center" 
            } 
        }

        return (
            <Fragment>
                <article ref={div => this._pageRef = div} className={"page philosophy"}>
                    <div className={"page-content"}>
                        <h1>Philosophy | What is “Rehabilitation Redefined” and why is Casa Lupita like no place on earth?</h1>
                        <h2>The Mission</h2>
                        <p>Our goal, during your stay at Casa Lupita, is to bring the dignity of independence and mobility to your child or loved one, and to promote the 
                        mental and physical health of not just the patient, but of their families.</p>
                        <p>Casa Lupita’s aim is to provide a refuge and sanctuary to you, while offering an unparalleled five star vacation experience in a tropical 
                        paradise, with first class rehabilitation services.</p>
                        <p>You can embrace the fact that you have not only made your holiday more meaningful, but that all the proceeds 
                        from your stay are contributed to funding Lupita Project 
                        <a href="http://www.lupitaproject.com" rel="noopener" arial-label="Lupita Project Site">lupitaproject.com</a> a 
                        not for profit foundation that offers a range of rehabilitation services to the Pacific Coast of Oaxaca.</p>
                    </div>                    
                    {
                        hasLoaded && 
                        <div className="modules">
                    
                            <Module isHeader={true} isFullSize={true} {...mProps}>
                                <ModuleBackground 
                                    device={this._devSize}
                                    image={this.assetRef("philo_hero_shot")}
                                    video={{ desktop: getVid, delay: this._isPhone ? this._delay : 0 }}
                                />
                                <ModuleContent 
                                    textContentClass="text-align-center align-center v-bottom"
                                    isFullSize={true}
                                    heading="Philosophy"
                                    body={"What is “Rehabilitation Redefined” and why is Casa Lupita like no place on earth?"}
                                />
                                <div ref={this._scrollRef} className="module-scroll-down show-scroll"><ScrollDown /></div>
                            </Module>

                            <ModuleLayer backgroundColor="white" paddingBottom={0} {...mProps}>

                                <ImageLayer 
                                    classes="first-pad"
                                    alt="Map location of Casa Lupita"
                                    asset={this.assetRef("ph_wheelchair_van")}
                                    x={940} y={107} width={970} height={724}
                                />
                                
                                <TextLayer classes="large w-396" x={267} y={174} width={490} height={234} titleHtml={"The Mission"}>
                                    <Fragment>
                                    Casa Lupita is the brainchild of Dr. Andrew Glassman, who has dedicated two decades of his life to helping children and families in need, through his Lupita Project, a not for profit charitable foundation based in Santa Maria Huatulco, Mexico. 
                                    <p>Devoted to enriching the lives of those who suffer disabling infirmities, Dr. Glassman’s organization has brought the gift of comfort and mobility, as well as the dignity of independence, to countless families.</p>
                                    </Fragment>
                                </TextLayer>

                            </ModuleLayer>
                            
                            <ModuleLayer backgroundColor="white" ignoreTopPadding={true} {...mProps}>

                                <ImageLayer 
                                    classes="about-lamp"
                                    alt="Casa Lupita hallway lamp"
                                    asset={this.assetRef("ph_lamp")}
                                    x={0} y={0} width={1125} height={1345}
                                />

                                <TextLayer classes="large w-396 t-268" x={1207} y={95} width={452} height={655} titleHtml={"About<div>The Lupita Project</div>"}>
                                    <p>The Lupita project is a non-profit that has been working in close collaboration with the DIF, a Mexican 
                                    public institution of social assistance, that focuses on strengthening and developing the welfare of Mexican 
                                    families. The Lupita project has been providing a much needed safety net for children and adults, without 
                                    access to resources, as well as  a range of vital services to those who live in the coastal region of
                                     Oaxaca, Mexico.</p>
                                </TextLayer>

                                <ImageLayer 
                                    classes="girl-pickup"
                                    alt="Disabled girl creating arts and crafts"
                                    asset={this.assetRef("ph_girl_pick_up")}
                                    x={0} y={1181} width={1920} height={1727}
                                />

                                <TextLayer classes="bottom-space large t-1006" x={195} y={1423} width={612} height={448}>
                                    Dr. Glassman’s project began with the notion that medical devices like canes, crutches and wheelchairs, 
                                    with very little wear and tear, and which could no longer be rented, could in fact find a productive 
                                    second life in Mexico.  The program has grown to offer specific synergistic areas of treatment, at no 
                                    cost to its patients, through its alliance with Shriners Hospital for Children, as well as hydrotherapy 
                                    and extending clinical physical therapy at home, for those patients for which transportation 
                                    is a challenge.
                                </TextLayer>

                                <ImageLayer 
                                    alt="Girl smiling at camera"
                                    asset={this.assetRef("ph_girl_01")}
                                    x={343} y={2569} width={668} height={891}
                                />
                                
                                <ImageLayer 
                                    alt="Girl writing on paper"
                                    asset={this.assetRef("ph_girl_02")}
                                    x={1110} y={2569} width={668} height={891}
                                />

                                <TextLayer classes="large w-1010" x={346} y={3514} width={1240} height={164}>
                                    You are encouraged to learn more about this incredible initiative and the difference it is making to the 
                                    lives of so many families at <a href="https://www.lupitaproject.com" rel="noopener" arial-label="Lupita Project Site">lupitaproject.com</a>
                                </TextLayer>

                            </ModuleLayer>
                            
                            <ModuleLayer backgroundColor="white" ignoreTopPadding={true} {...mProps} video={videoConfig}>

                                {/*<ImageLayer 
                                    alt="Kids enjoying dinner"
                                    asset={this.assetRef("ph_kids_eating")}
                                    x={0} y={0} width={954} height={859}
                                />*/}

                                {<VideoLayer 
                                    hasLazyLoaded={lz}
                                    poster={this.assetRef("ph_kids_eating").src()}
                                    asset={this.withPrefix("static/videos/minis/hd/ph_954x859.mp4")}
                                    x={0} y={0} width={954} height={859}
                                />}
                                
                                <TextLayer classes="light-gray-msg large-text bottom-space" baseFontSize={120} lineHeight="1.25" x={1045} y={40} width={765} height={765}>
                                    While forming lasting skills and memories...
                                </TextLayer>

                            </ModuleLayer>
                            
                            <ModuleLayer backgroundColor="white" {...mProps} video={videoConfig2}>

                                <ImageLayer 
                                    alt="Family enjoying the jacuzzi"
                                    asset={this.assetRef("ph_family_jacuzzi")}
                                    x={0} y={107} width={1920} height={1080}
                                />
                                
                                <TextLayer classes="bottom-space large" x={329} y={1292} width={587} height={334}>
                                    ...you can embrace the fact that you have not only made your holiday more meaningful, but that all the proceeds 
                                    from your stay are contributed to funding Lupita Project <a href="http://www.lupitaproject.com" rel="noopener" arial-label="Lupita Project Site">lupitaproject.com</a> a not for profit foundation that offers a range of rehabilitation services to the Pacific Coast of Oaxaca.
                                </TextLayer>
                                
                                {/*<ImageLayer 
                                    alt="Family walking down the hallway"
                                    asset={this.assetRef("ph_family_hallway")}
                                    x={1008} y={1122} width={907} height={692}
                                />*/}

                                {<VideoLayer 
                                    hasLazyLoaded={lz}
                                    poster={this.assetRef("ph_family_hallway").src()}
                                    asset={this.withPrefix("static/videos/minis/hd/ph_907x692.mp4")}
                                    x={1008} y={1122} width={907} height={692}
                                />}

                            </ModuleLayer>

                            <ModuleLayer
                                backgroundColor="white" 
                                {...mProps}
                                animate="push"
                            >
                                <ImageLayer 
                                    alt="Casa Lupita office"
                                    asset={this.assetRef("ph_casa_lupita_office")}
                                    x={0} y={0} width={1920} height={1080}
                                />
                            </ModuleLayer>      

                            <Module 
                                device={this._devSize}
                                hasRichLayout={true} 
                                classes="m-footer" 
                                backgroundColor="#0a0a0a" 
                                animate="footer"
                                hasLazyLoaded={lz}
                            >
                                <ModuleContent classes="full-width">
                                    <RichLayoutRow classes="">
                                        <RichLayoutColumn classes="flex-v-align-center">
                                            <RichLayoutBody>
                                                <Footer owner={this} pageRef={this._pageRef} hasLazyLoaded={lz} />
                                            </RichLayoutBody>
                                        </RichLayoutColumn> 
                                    </RichLayoutRow>
                                </ModuleContent>
                            </Module>
                        </div>
                    }

                </article>
            </Fragment>
        )
    }
}